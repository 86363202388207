







































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import ImageInput from "@/components/ImageInput.vue";

export default Vue.extend({
  components: { ImageInput },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      dialogType: false,
      formType: false,
      hideCreate: false,
      loadingCreate: false,
      snackbar: false,
      fails: "",
      name: "",
      form: false,
      dialogDelete: false,
      dialogApprove: false,
      dialogApproveSpending: false,
      dialogRejectApprove: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      ruleRequiredArray: (value) =>
        !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
      headers: [
        { text: "ID đề xuất", value: "id" },
        { text: "loại đề xuất", value: "type" },
        { text: "Người đề xuất", value: "created_by" },
        { text: "Nội dung đề xuất", value: "content" },
        { text: "Người phê duyệt", value: "approved_person" },
        { text: "Người xem", value: "object" },
        { text: "Trạng thái", value: "status" },
        { text: "Trạng thái chi", value: "spending_status" },
        { text: "chỉ thị", value: "directive" },
        { text: "Ngày tạo đề xuất", value: "created_at" },
        { text: this.$t("IMAGE"), value: "images" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      search: {
        created_by: "",
        type: "",
        approve: "",
        id: "",
        spending_status: "",
      },
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      allowDelete: {},
      users: [],
      approves: [],
      types: [],
      usersByRoles: [],
      userInfo: userManager.getUserInfo(),
      availableRoleMap: {
        k: this.$t("ROLE_K"),
        xnk: this.$t("ROLE_XNK"),
        kd: this.$t("ROLE_KD"),
        t: this.$t("ROLE_T"),
        kt: this.$t("ROLE_KT"),
        td: this.$t("ROLE_TD"),
        gd: this.$t("ROLE_GD"),
        htkd: this.$t("ROLE_HTKD"),
        cs: "Chính sách",
        kvn: "Kho VN",
        ktt: "Kế toán trưởng",
        mkt: "Marketing",
      },
      urlFunc(item) {
        return Vue.filter("suggestionImageUrl")(item);
      },
      dialogApproveSpendingForm: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo đề xuất" : this.$t("EDIT");
    },
    availableProgresses() {
      const items = [];
      for (const k in this.availableProgressMap) {
        items.push({
          text: this.availableProgressMap[k],
          value: k,
        });
      }
      return items;
    },
    availableRoles() {
      const items = [];
      for (const k in this.availableRoleMap) {
        items.push({
          text: this.availableRoleMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    dialogType: {
      handler(val) {
        if (val === false) {
          this.hideCreate = false;
          this.name = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      const filters = [];
      const search = { ...this.search };
      for (const searchKey in search) {
        if (search[searchKey]) {
          if (searchKey === "id") {
            search[searchKey] = search[searchKey].replace("ĐX", "");
          }
          if (searchKey === "title") {
            filters.push({
              key: searchKey,
              operator: "LIKE",
              value: `%${search[searchKey]}%`,
            });
          } else if (searchKey === "approve") {
            filters.push({
              key: searchKey,
              operator: "in_json",
              value: [search[searchKey]],
            });
          } else {
            filters.push({
              key: searchKey,
              operator: "=",
              value: search[searchKey],
            });
          }
        }
      }
      this.loading = true;
      const { items, count } = await apiClient.suggestionsGetAll({
        options: this.options,
        filters,
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (!Array.isArray(this.editedItem.object)) {
        this.editedItem.object = [this.editedItem.object];
      } else {
        this.editedItem.object = [...new Set(this.editedItem.object)];
      }
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    approveSpendingItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.id = item.id;
      this.dialogApproveSpending = true;
    },
    approveItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.id = item.id;
      this.dialogApprove = true;
    },
    rejectItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem.id = item.id;
      this.dialogRejectApprove = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.suggestionsDelete(this.editedItem);
      await this.initialize();
    },
    async approveItemConfirm() {
      this.closeApprove();
      const params = {
        status: "1",
        directive: this.editedItem.directive,
        approved_person: String(this.userInfo.sub),
        id: this.editedItem.id,
      };
      await apiClient.suggestionsApprove(params);
      await this.initialize();
    },
    async approveSpendingItemConfirm() {
      this.closeApproveSpending();
      const params = {
        spending_status: "1",
        id: this.editedItem.id,
        images: this.editedItem.images,
      };
      await apiClient.suggestionsApproveSpending(params);
      await this.initialize();
    },
    async rejectApproveItemConfirm() {
      this.closeRejectApprove();
      const params = {
        status: "2",
        directive: this.editedItem.directive,
        approved_person: String(this.userInfo.sub),
        id: this.editedItem.id,
      };
      await apiClient.suggestionsApprove(params);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeApproveSpending() {
      this.dialogApproveSpending = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeRejectApprove() {
      this.dialogRejectApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      const userInfo = { ...this.userManager.getUserInfo() };
      if (userInfo.sub) {
        this.editedItem["created_by"] = userInfo.sub;
      }
      if (
        this.editedItem.object &&
        Array.isArray(this.editedItem.object) &&
        this.editedItem.object.length > 0
      ) {
        this.editedItem.object = this.editedItem.object.filter(
          (id) => id !== "all" && id !== null
        );
      } else {
        delete this.editedItem.object;
      }
      if (
        this.editedItem.approve &&
        Array.isArray(this.editedItem.approve) &&
        this.editedItem.approve.length > 0
      ) {
        this.editedItem.approve = this.editedItem.approve.filter(
          (id) => id !== "all" && id !== null
        );
      } else {
        delete this.editedItem.approve;
      }
      if (
        Array.isArray(this.editedItem.roles) &&
        this.editedItem.roles.length === 0
      ) {
        this.editedItem.roles = [];
      }
      if (this.editedIndex > -1) {
        result = await apiClient.suggestionsUpdate(this.editedItem);
      } else {
        result = await apiClient.suggestionsCreate(this.editedItem);
      }
      if (!result.errors) {
        this.close();
        await this.initialize();
      } else {
        this.snackbar = true;
        this.fails = result.message;
      }
    },
    getAllUser() {
      this.usersByRoles = [];
      apiClient
        .userGetAll()
        .then((res) => {
          this.users = [
            {
              id: "all",
              name: "Tất cả",
            },
          ].concat(
            res.items.map((item) => {
              item.id = String(item.id);
              return item;
            })
          );

          this.approves = [...this.users];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    getName(value, data, keyData = "id", keyName = "name") {
      if (value) {
        if (Array.isArray(data)) {
          const length = data.length;
          for (let i = 0; i < length; i++) {
            if (data[i][keyData] === value) {
              return data[i][keyName];
            }
          }
        }
        return value;
      }
      return "";
    },
    goToDetailsObject(record) {
      console.log(record);
    },
    onChangeObject(val) {
      const isAll = val.includes("all");
      if (isAll) {
        this.editedItem.object = this.users.map((item) => {
          return item.id;
        });
        this.users.shift();
        this.users.unshift({
          name: "Bỏ chọn",
          id: "removeAll",
        });
      }
      if (val.includes("removeAll")) {
        this.editedItem.object = [];
        this.users.shift();
        this.users.unshift({
          name: "Tất cả",
          id: "all",
        });
      }
    },
    onChangeApprove(val) {
      const isAll = val.includes("all");
      if (isAll) {
        this.editedItem.approve = this.approves.map((item) => {
          return item.id;
        });
        this.users.shift();
        this.users.unshift({
          name: "Bỏ chọn",
          id: "removeAll",
        });
      }
      if (val.includes("removeAll")) {
        this.editedItem.approve = [];
        this.users.shift();
        this.users.unshift({
          name: "Tất cả",
          id: "all",
        });
      }
    },
    async suggestionsCodeDelete(id) {
      this.loadingCreate = true;
      const x = await apiClient.suggestionsCodeDelete(id);
      if (x) {
        this.suggestionsCode();
        this.loadingCreate = false;
      }
    },
    suggestionsCode() {
      const filters = [];
      apiClient
        .suggestionsCodeGetAll({
          options: {},
          filters,
        })
        .then((res) => {
          this.types = res.items.map((item) => {
            return {
              name: item.name,
              id: String(item.id),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    async saveCode() {
      this.hideCreate = !this.hideCreate;
      this.loadingCreate = true;
      const params = {
        name: this.name,
      };
      const res = await apiClient.suggestionsCodeCreate(params);
      if (res) {
        this.suggestionsCode();
        this.name = "";
        this.loadingCreate = false;
      }
    },
    closeType() {
      this.dialogType = false;
    },
  },
  created() {
    this.getAllUser();
    this.suggestionsCode();
  },
});
